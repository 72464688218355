import IFrameResizer from 'iframe-resizer-react';
import React from 'react';

const RegulatoryNews: React.FC = () => (
  <>
    <IFrameResizer
      width="100%"
      data-cy="regulatory-news-iframe"
      title="Regulatory News"
      src={process.env.GATSBY_INVESTIS_URL}
      frameBorder="0"
    />
  </>
);

export default RegulatoryNews;
