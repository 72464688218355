import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import RegulatoryNews from 'components/RegulatoryNews';
import HeroPlain, { HeroPlainData } from 'components/blocks/HeroBanner/HeroPlain';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { PageMeta } from 'types/contentStack';

type Props = {
  data: RegulatoryNewsPageData;
};

const RegulatoryNewsPage: React.FC<Props> = ({ data }) => {
  const page = data.csRegulatoryNewsPageTemplate;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);

  return (
    <Layout
      url={page.url}
      pageMetadata={meta}
      breadcrumbs={breadcrumbs}
      locale={page.locale}>
      <HeroPlain hero_plain={data.csRegulatoryNewsPageTemplate.hero_plain} />
      <RegulatoryNews />
    </Layout>
  );
};

export default RegulatoryNewsPage;

type RegulatoryNewsPageData = {
  csRegulatoryNewsPageTemplate: {
    title: string;
    url: string;
    locale: string;
    hero_plain: HeroPlainData;
    page_meta: PageMeta;
  };
};

export const query = graphql`
  query($id: String!) {
    csRegulatoryNewsPageTemplate(id: { eq: $id }) {
      title
      url
      locale
      hero_plain {
        ...HeroPlain
      }
      page_meta {
        ...PageMeta
      }
    }
  }
`;
